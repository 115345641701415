<template>
  <div>
    <div>
      <v-app-bar app color="primary" dark class="pa-0">
        <v-img
          class="flex-grow-1"
          src="/logo-half-white.svg"
          height="50px"
          width="170px"
          contain
          position="center left"
        />

        <div v-if="$vuetify.breakpoint.mdAndUp">
          <v-btn
            v-for="([text, ref], i) in items"
            :key="i"
            @click="$vuetify.goTo($refs[ref])"
            small
            text
            class="py-5"
          >
            {{ text }}
          </v-btn>
          <v-btn to="/aplicativo" small color="white" class="py-4 primary--text"
            >Aplicativo eConsenso
          </v-btn>
        </div>

        <v-app-bar-nav-icon v-else @click="drawer = !drawer" class="mr-2" />
      </v-app-bar>

      <v-navigation-drawer
        v-model="drawer"
        app
        right
        temporary
        light
        color="grey lighten-5"
      >
        <v-img height="100px" src="/eConsenso.png" contain class="mt-4 mb-2" />

        <v-list>
          <v-divider />
          <v-list-item-group v-for="([text, ref], i) in items" :key="i">
            <v-list-item
              @click="
                $vuetify.goTo($refs[ref]);
                drawer = false;
              "
              link
            >
              <v-list-item-content class="text-subtitle-2 font-weight-medium">
                {{ text }}
              </v-list-item-content>
              <v-list-item-action>
                <v-icon>mdi-chevron-right</v-icon>
              </v-list-item-action>
            </v-list-item>

            <v-divider />
          </v-list-item-group>
        </v-list>
      </v-navigation-drawer>
    </div>

    <v-parallax class="head" height="550" src="@/assets/landing-page/background.jpg">
      <v-layout column align-center justify-center>
        <v-flex shrink>
          <v-img
            alt="Logo eConsenso"
            src="/eConsenso.png"
            transition="scale-transition"
            contain
            height="200px"
            eager
            rel="preload"
          />
          <div class="subtitle1 white--text text-center">
            Solicite Consentimentos de forma Digital, seguindo as bases da LGPD,
          </div>
          <div class="subtitle1 white--text text-center">
            sem Burocracia e com total Segurança.
          </div>
        </v-flex>
        <v-layout shrink>
          <div align="center">
            <v-btn
              color="primary"
              width="250"
              class="mt-4 ml-4"
              @click="$vuetify.goTo($refs.introduction)"
            >
              Saiba mais
            </v-btn>
            <v-btn
              color="secundary"
              width="250"
              class="mt-4 ml-4"
              to="/aplicativo"
              v-if="$vuetify.breakpoint.smAndDown"
            >
              Aplicativo eConsenso
              <v-icon class="ml-1"> mdi-cellphone </v-icon>
            </v-btn>
            <v-btn color="secundary" width="250" class="mt-4 ml-4" to="/login">
              Área de Usuário
              <v-icon class="ml-1"> mdi-login-variant </v-icon>
            </v-btn>
          </div>
        </v-layout>
      </v-layout>
    </v-parallax>

    <div class="d-flex justify-center" ref="introduction">
      <v-sheet
        width="100%"
        min-height="320px"
        max-width="1200px"
        class="d-flex align-center justify-start"
      >
        <!-- `${$vuetify.breakpoint.width * ($vuetify.breakpoint.smAndDown ? 1:0.5)}px` -->
        <div
          :style="{ width: $vuetify.breakpoint.smAndDown ? '100%' : '60%' }"
          class="text-h6 font-weight-medium float-left pa-8"
          :class="{
            'text-center': $vuetify.breakpoint.smAndDown,
            'text-justify': $vuetify.breakpoint.mdAndUp,
          }"
        >
          <v-img
            v-if="$vuetify.breakpoint.smAndDown"
            contain
            src="@/assets/landing-page/computer.png"
            height="300px"
            class="mb-4"
          />
          <p>
            O eConsenso é a mais nova solução para auxiliar sua Empresa a
            adequar-se às novas Diretrizes da LGPD. Através dele você pode
            solicitar e armazenar consentimentos de forma online, e seus
            clientes poderão responder às suas solicitações através do
            aplicativo eConsenso.
          </p>
          <p>
            Você deseja conhecer um pouco mais sobre o eConsenso? Clique no
            botão abaixo e receba diretamente no seu e-mail mais detalhes!
          </p>
          <div
            :class="{
              'text-end': $vuetify.breakpoint.mdAndUp,
            }"
          >
            <v-btn
              color="success"
              large
              @click="
                (formDialog.title =
                  'Responda o formulário abaixo e receba no seu e-mail todas as informações referentes ao eConsenso:'),
                  (formDialog.button = 'Enviar'),
                  (formDialog.buttonMobile = null),
                  (formDialog.data.stage = 'Educação - Sistema'),
                  (formDialog.data.tags = ['LP Institucional Sistema']),
                  (formDialog.active = true)
              "
            >
              CLIQUE AQUI E SAIBA MAIS!
            </v-btn>
          </div>
        </div>
        <div class="float-left" v-if="$vuetify.breakpoint.mdAndUp">
          <img
            src="@/assets/landing-page/computer.png"
            style="
              overflow: visible;
              margin-top: -260px;
              margin-left: -30px;
              height: 450px;
              position: absolute;
            "
          />
        </div>
      </v-sheet>
    </div>

    <v-sheet class="primary d-flex justify-center" ref="works">
      <v-sheet
        color="transparent"
        class="d-block pa-9"
        width="auto"
        max-width="1200px"
      >
        <div
          class="d-flex"
          :class="{ 'justify-center': $vuetify.breakpoint.smAndDown }"
        >
          <div
            class="
              grey
              lighten-4
              primary--text
              text-h4
              d-inline-block
              font-weight-bold
              px-3
              py-1
              mt-6
              rounded
              elevation-1
            "
          >
            Como Funciona
          </div>
        </div>
        <p
          class="text-h6 font-weight-medium white--text py-4"
          :class="{ 'text-center': $vuetify.breakpoint.smAndDown }"
        >
          O eConsenso veio para facilitar todo processo de Construção,
          Solicitação e Armazenamento de Consentimentos da sua Empresa, seguindo
          todas as diretrizes da LGPD. Você irá contar com toda Facilidade e
          Segurança, em uma plataforma totalmente online.
          <strong>Confira:</strong>
        </p>

        <div class="d-flex flex-column">
          <template v-for="(step, i) in howItWorks">
            <!-- 

              height: $vuetify.breakpoint.smAndDown ? '150px':'200px',
              
             -->
            <div :key="i" class="d-flex align-stretch">
              <v-img
                :src="step.img"
                contain
                :width="`${Math.min(
                  Math.max($vuetify.breakpoint.width * 0.2, 100),
                  200
                )}px`"
                :max-width="`${Math.min(
                  Math.max($vuetify.breakpoint.width * 0.2, 100),
                  200
                )}px`"
              />

              <div class="flex-grow-1 d-flex flex-column pl-2">
                <!-- <div class="fill-height d-flex " style="width: 100%"> -->
                <div class="line flex-grow-1">
                  <template v-if="i > 0">
                    <div />
                    <div class="elevation-10" />
                    <div />
                  </template>
                </div>
                <v-card
                  class="flex-grow-"
                  :min-height="$vuetify.breakpoint.smAndDown ? 0 : '100px'"
                  width="100%"
                  color="transparent"
                  elevation="2"
                  style="border: 2px solid white !important"
                >
                  <v-card-text
                    class="
                      font-weight-medium
                      white--text
                      fill-height
                      d-flex
                      align-center
                    "
                    :class="{
                      /* desktop */
                      'text-h5': $vuetify.breakpoint.mdAndUp,
                      'text-justify': $vuetify.breakpoint.mdAndUp,
                      'pa-8': $vuetify.breakpoint.mdAndUp,
                      /* mobile */
                      'text-subtitle-1': $vuetify.breakpoint.sm,
                      'text-subtitle-2': $vuetify.breakpoint.xs,
                    }"
                  >
                    <strong class="mr-4">{{ i + 1 }}:</strong> {{ step.text }}
                  </v-card-text>
                </v-card>
                <div class="line flex-grow-1">
                  <template v-if="i < howItWorks.length - 1">
                    <div />
                    <div />
                    <div />
                  </template>
                </div>
                <!-- </div> -->
              </div>
            </div>
          </template>
        </div>
      </v-sheet>
    </v-sheet>

    <v-sheet class="d-flex justify-center" ref="plans">
      <v-sheet class="pa-9" width="auto" max-width="1200px">
        <div class="title1 primary--text">Nossos Planos</div>

        <Plans :purchaseAvailable="false" hideCard />
      </v-sheet>
    </v-sheet>

    <v-sheet class="pa-9 d-flex justify-center blue-grey lighten-5" ref="lgpd">
      <v-sheet
        class="blue-grey lighten-5 d-flex"
        :class="{
          'flex-column': $vuetify.breakpoint.smAndDown,
        }"
        width="auto"
        max-width="1200px"
      >
        <div
          class="pa-9"
          :class="{
            'text-center': $vuetify.breakpoint.smAndDown,
            'text-end': $vuetify.breakpoint.mdAndUp,
          }"
        >
          <div class="title1 primary--text">
            O que é a Lei Geral de Proteção de Dados (LGPD) e Como seguir as
            normas na minha Empresa?
          </div>
          <br />
          <div class="subtitle1 black--text">
            Para saber mais sobre a LGPD e adequar à sua empresa, responda o
            formulário abaixo e receba em seu e-mail nosso e-book exclusivo: "A
            LGPD Simplificada e o 11 Passos para implantá-la na sua Empresa".
          </div>
          <br />
          <div>
            <v-btn
              color="success"
              large
              @click="
                (formDialog.title =
                  'Responda o formulário abaixo para que o E-book seja liberado'),
                  (formDialog.button = 'RECEBA AGORA O E-BOOK NO SEU E-MAIL'),
                  (formDialog.buttonMobile = 'Solicitar E-Book'),
                  (formDialog.data.stage = 'Educação - LGPD'),
                  (formDialog.data.tags = ['LP Institucional E-Book']),
                  (formDialog.active = true)
              "
            >
              Solicitar E-Book
            </v-btn>
          </div>
        </div>
        <div class="fill-height d-flex align-center">
          <v-img
            src="@/assets/landing-page/ebook.jpg"
            contain
            class="mt-4"
            width="375px"
            max-width="100%"
          />
        </div>
      </v-sheet>
    </v-sheet>

    <!-- <v-container>
      <v-layout column my-12>
        <v-flex sm="5">
          <div class="title1 primary--text">BLOG</div>
          <v-layout wrap justify-space-around mt-4 align-center>
            <v-flex
              v-for="card in cards"
              :key="card.title"
              :cols="card.flex"
              xs12
              sm6
              md6
              lg4
              pa-1
            >
              <v-card>
                <v-img :src="card.src" height="200px"> </v-img>

                <v-card-title> {{ card.title }} </v-card-title>

                <v-card-text>
                  <div>{{ card.subtitle }}</div>
                  <div>...</div>
                </v-card-text>
                <v-card-actions>
                  <v-btn color="success" text> Ver Mais </v-btn>
                </v-card-actions>
              </v-card>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-container> -->
    <v-divider />

    <v-sheet class="pa-9 d-flex justify-center blue-grey lighten-3" ref="about">
      <v-sheet
        class="blue-grey lighten-3 d-flex"
        :class="{
          'flex-column': $vuetify.breakpoint.smAndDown,
        }"
        width="auto"
        max-width="1200px"
      >
        <div
          class="pa-9"
          :class="{
            'text-center': $vuetify.breakpoint.smAndDown,
            'text-justify': $vuetify.breakpoint.mdAndUp,
          }"
        >
          <div class="title1 primary--text">Quem Somos?</div>
          <br />
          <div class="subtitle1 black--text">
            Somos a Almeida Technologies, uma empresa especializada em
            desenvolvimento de soluções Tecnológicas Empresariais. Estamos no
            mercado há mais de 5 anos, atendendo a praticamente todos os nichos
            de mercado e em todo o Brasil.
          </div>
        </div>
        <div class="fill-height d-flex align-center">
          <v-img
            src="@/assets/logo_almeida.png"
            contain
            class="mt-4"
            width="375px"
            max-width="100%"
          />
        </div>
      </v-sheet>
    </v-sheet>

    <v-parallax
      ref="contact"
      id="contactUsParallax"
      :src="contactUsImage"
      class="primary"
      :style="{
        height: $vuetify.breakpoint.mobile ? 'auto' : '550px',
      }"
    >
      <v-container>
        <div align="center">
          <v-layout row justify-center>
            <v-flex shrink my-6>
              <v-layout align-center fill-height>
                <v-layout column justify-center class="white--text break">
                  <div class="title1">Fale Conosco</div>
                  <div class="subtitle1">Estamos dispostos a respondê-lo.</div>
                  <br />
                  <v-chip
                    v-for="(contact, i) in commonContacts"
                    :key="i"
                    label
                    color="grey lighten-4"
                    class="primary--text mb-2 elevation-10"
                    :target="contact.site ? '_blank' : ''"
                    :href="!contact.label ? contact.link : ''"
                  >
                    <v-icon class="mr-2">{{ contact.icon }}</v-icon>
                    {{ contact.text }}
                  </v-chip>
                  <v-layout row wrap mt-2>
                    <template v-for="(contact, i) in contacts">
                      <v-btn
                        :key="i"
                        small
                        fab
                        class="primary--text elevation-10 mx-1 mt-1 ml-3"
                        :href="contact.link"
                        :target="contact.site ? '_blank' : ''"
                      >
                        <v-icon>{{ contact.icon }}</v-icon>
                      </v-btn>
                    </template>
                  </v-layout>
                  <br />
                </v-layout>
              </v-layout>
            </v-flex>

            <v-flex :shrink="!mobile" pa-2 my-6>
              <v-card
                :class="{ ' ml-4': !mobile }"
                :min-width="mobile ? 0 : 400"
              >
                <v-card-title class="subtitle1 py-2 primary--text">
                  Contate-nos
                </v-card-title>
                <v-card-text class="pb-0">
                  <v-form ref="contactUsForm">
                    <template>
                      <v-text-field
                        dense
                        filled
                        :disabled="contactUs.loading"
                        label="Nome *"
                        hide-details="auto"
                        v-model="contactUs.data.name"
                        :rules="[$rules.required]"
                      />
                      <v-text-field
                        dense
                        filled
                        :disabled="contactUs.loading"
                        label="E-Mail *"
                        hide-details="auto"
                        v-model="contactUs.data.email"
                        :rules="[$rules.required, $rules.email]"
                      />
                      <v-text-field
                        dense
                        filled
                        :disabled="contactUs.loading"
                        label="Telefone *"
                        hide-details="auto"
                        v-model="contactUs.data.phone"
                        v-mask="['(##) ####-####', '(##) #####-####']"
                        placeholder="(00) 00000-0000"
                        :rules="[$rules.required, $rules.phone]"
                      />
                    </template>
                    <v-text-field
                      dense
                      filled
                      :disabled="contactUs.loading"
                      hide-details="auto"
                      label="Assunto *"
                      v-model="contactUs.data.subject"
                      :rules="[$rules.required]"
                    />
                    <v-textarea
                      dense
                      hide-details="auto"
                      filled
                      :disabled="contactUs.loading"
                      label="Mensagem *"
                      v-model="contactUs.data.message"
                      :rules="[$rules.required]"
                      :rows="3"
                      no-resize
                    />
                  </v-form>
                </v-card-text>
                <v-card-actions>
                  <v-spacer />
                  <v-btn
                    color="primary"
                    large
                    :loading="contactUs.loading"
                    @click="sendContactUs()"
                  >
                    Enviar
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-flex>
          </v-layout>
        </div>
      </v-container>
    </v-parallax>

    <v-footer color="primary white--text" class="d-flex justify-center py-0">
      <v-icon small class="white--text mr-2">mdi-copyright</v-icon>
      <span class="overline"> Almeida Technologies LTDA </span>
    </v-footer>

    <v-dialog
      v-model="formDialog.active"
      max-width="500px"
      :persistent="formDialog.loading"
    >
      <v-card>
        <div align="end">
          <v-btn
            icon
            @click="formDialog.active = false"
            :disabled="formDialog.loading"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
        <v-card-title class="py-0 justify-center"
          ><div align="center" class="subtitle1 ma-5 font-weight-bold break">
            {{ formDialog.title }}
          </div>
        </v-card-title>

        <v-card-text class="pt-4">
          <v-form ref="leadsForm">
            <v-text-field
              label="Nome *"
              v-model="formDialog.data.name"
              dense
              class="mt-2"
              :rules="[$rules.required]"
              :disabled="formDialog.loading"
            />
            <v-text-field
              label="E-Mail *"
              v-model="formDialog.data.email"
              dense
              class="mt-2"
              :rules="[$rules.required, $rules.email]"
              :disabled="formDialog.loading"
            />
            <v-text-field
              label="Telefone *"
              v-model="formDialog.data.phone_number"
              dense
              class="mt-2"
              :rules="[$rules.required, $rules.phone]"
              v-mask="['(##) ####-####', '(##) #####-####']"
              placeholder="(00) 00000-0000"
              :disabled="formDialog.loading"
            />
            <v-text-field
              label="Qual a sua Empresa atualmente?"
              v-model="formDialog.data.company"
              dense
              class="mt-2"
              :disabled="formDialog.loading"
            />
            <v-text-field
              label="E qual a sua posição nessa Empresa?"
              v-model="formDialog.data.company_position"
              dense
              class="mt-2"
              :disabled="formDialog.loading"
            />
            <v-checkbox
              label="Concordo em receber comunicações"
              :disabled="formDialog.loading"
              :rules="[(v) => !!v || 'Você deve concordar para continuar']"
            />
            <p class="text-subtitle-2 grey--text text--darken-1">
              Uma mensagem com mais informações será enviada para seu e-mail.
              Confira também sua caixa de spam.
            </p>
            <div align="center" justify="center">
              <v-btn
                class="my-2"
                block
                large
                color="success"
                :loading="formDialog.loading"
                @click="sendForm()"
              >
                <template
                  v-if="
                    $vuetify.breakpoint.mobile &&
                    formDialog.buttonMobile != null
                  "
                >
                  {{ formDialog.buttonMobile }}
                </template>
                <template v-else>
                  {{ formDialog.button }}
                </template>
              </v-btn>
            </div>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import App from "@/App.vue";
import Plans from "@/components/SettingsTabs/FinancialPage/Plans.vue";

export default {
  name: "Landing",
  components: { App, Plans },
  data() {
    return {
      drawer: false,
      items: [
        ["Sobre", "introduction"],
        ["Como Funciona", "works"],
        ["Nossos Planos", "plans"],
        ["LGPD", "lgpd"],
        ["Quem Somos", "about"],
        ["Fale Conosco", "contact"],
      ],
      contactUs: {
        loading: false,
        data: {
          name: "",
          email: "",
          phone: "",
          newLead: true,
          subject: "",
          message: "",
          tags: [],
        },
      },
      formDialog: {
        active: false,
        loading: false,
        title: "",
        button: "",
        buttonMobile: null,
        data: {
          name: "",
          email: "",
          phone: "",
          company: "",
          company_position: "",
          checkbox: false,
          stage: "Educação - LGPD",
        },
      },
      cards: [
        {
          title: "Pre-fab homes aaaaaaa",
          subtitle: "Descrição",
          src: "https://cdn.vuetifyjs.com/images/cards/house.jpg",
          flex: 4,
        },
        {
          title: "Favorite roaddddddddd",
          subtitle: "Descrição",
          src: "https://cdn.vuetifyjs.com/images/cards/road.jpg",
          flex: 4,
        },
        {
          title: "Best airlines",
          subtitle: "Descrição",
          src: "https://cdn.vuetifyjs.com/images/cards/plane.jpg",
          flex: 4,
        },
      ],
      howItWorks: [
        {
          img: require("@/assets/landing-page/how-it-works/step1.jpg"),
          text: "Acesse a Plataforma do eConsenso;",
        },
        {
          img: require("@/assets/landing-page/how-it-works/step2.jpg"),
          text: "Defina os termos do seu Consentimento e faça a solicitação;",
        },
        {
          img: require("@/assets/landing-page/how-it-works/step3.jpg"),
          text: "O Cliente recebe a notificação no Celular para o Aceite ou Recusa, através do nosso App;",
        },
        {
          img: require("@/assets/landing-page/how-it-works/step4.jpg"),
          text: "Pronto! Consentimento Criado, Enviado, Aceito ou Não, e Registrado.",
        },
      ],
      commonContacts: [
        {
          icon: "mdi-gmail",
          text: "contato@econsenso.com.br",
          link: "mailto:contato@econsenso.com.br",
        },
        {
          icon: "mdi-phone",
          text: "(48) 4042-0601",
          link: "tel:+554840420601",
        },
     /*   {
          icon: "mdi-skype",
          text: "Nome skype: ?",
          link: "skype:malhafinacontador?chat",
        },*/
      ],
      contacts: [
        {
          icon: "mdi-facebook",
          text: "facebook.com/eConsenso",
          link: "https://www.facebook.com/eConsenso-106012065041907",
          site: true,
        },
        {
          icon: "mdi-instagram",
          text: "instagram.com/econsensobr",
          link: "https://www.instagram.com/econsensobr/",
          site: true,
        },
        {
          icon: "mdi-whatsapp",
          text: "https://wa.me/554840420601",
          link: "https://wa.me/554840420601",
          site: true,
        },
      ],
      plans: [
        {
          title: "GRÁTIS",
          consents: "200 consentimentos",
          price: "0,00",
        },
        {
          title: "SMALL",
          consents: "1000 consentimentos",
          price: "500,00",
        },
        {
          title: "PRO",
          consents: "5000 consentimentos",
          price: "1750,00",
        },
        {
          title: "BUSINESS",
          consents: "Consentimentos Ilimitados",
          price: "Sob Consulta",
        },
      ],
    };
  },

  computed: {
    mobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    contactUsImage() {
      if (this.mobile) {
        return undefined;
      }
      return require("@/assets/landing-page/contact-background.jpg");
    },
  },
  methods: {
    sendForm() {
      if (this.$refs.leadsForm.validate()) {
        this.formDialog.loading = true;
        this.$axios
          .post("/lead", this.formDialog.data)
          .then(() => {
            this.formDialog.active = false;
            this.$showMessage(
              "success",
              " O seu contato foi enviado com sucesso. Em muito breve receberá nosso e-mail. Até mais!"
            );
          })
          .catch(() => {
            this.$showMessage(
              "error",
              "Ocorreu um erro inesperado. Por favor, contate nosso suporte em contato@econsenso.com.br"
            );
          })
          .finally(() => {
            this.formDialog.loading = false;
          });
      } else {
        this.$showMessage(
          "warning",
          "Por favor, preencha os campos obrigatórios"
        );
      }
    },
    sendContactUs() {
      if (this.$refs.contactUsForm.validate()) {
        this.contactUs.loading = true;
        this.$axios
          .post("/contact-us", this.contactUs.data)
          .then(() => {
            this.contactUs.active = false;
            this.$showMessage(
              "success",
              " O seu contato foi enviado com sucesso. Em muito breve receberá nosso retorno. Até mais!"
            );
            this.$refs.contactUsForm.reset();
          })
          .catch(() => {
            this.$showMessage(
              "error",
              "Ocorreu um erro inesperado. Por favor, contate nosso suporte em contato@econsenso.com.br"
            );
          })
          .finally(() => {
            this.contactUs.loading = false;
          });
      } else {
        this.$showMessage(
          "warning",
          "Por favor, preencha os campos obrigatórios"
        );
      }
    },
  },
};
</script>


<style scoped>
.break {
  word-break: break-word;
}
.title1 {
  font-size: 34px;
  font-weight: bold;
}
.subtitle1 {
  font-size: 21px;
}
.line {
  display: flex;
  align-items: stretch;
}
.line > div {
  min-height: 20px;
  flex-grow: 1;
}
.line > div:nth-child(2) {
  width: 1.5px;
  flex-grow: 0;
  background: white;
  overflow: visible;
}
</style>

<style>
#contactUsParallax .v-parallax__image {
  min-height: 0;
}
</style>
